import * as React from 'react';
import { Link } from 'gatsby';
import mars from '../images/mars.png';
import * as styles from '../assets/styles/notFound.module.sass';
import * as baseStyles from '../assets/styles/base.module.sass';

const NotFoundPage = () => {
	return (
		<main className={styles.notFound}>
			<div className={styles.image}>
				<img src={mars} alt='mars'></img>
			</div>
			<h1 className={baseStyles.container}>
				Sorry, the page you are looking for has gone on an excursion to mars
			</h1>
			<Link to='/'>
				<h3>Go home</h3>
			</Link>
		</main>
	);
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
